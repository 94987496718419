<template>
  <a-card :bordered="false">
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
      title="用户储值卡列表"
      sub-title=""
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24" v-if="false">
            <a-form-item label="卡号">
              <a-input v-model="queryParam.card_no" placeholder="请输入卡号" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24" v-if="false">
            <div class="table-operator">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </div>
          </a-col>
          <a-col :md="2" :sm="24">
            <div class="table-operator">
              <a-button type="primary" icon="plus" @click="handleAddValueCard">添加新卡</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="price_render" slot-scope="text" style="text-align: left; min-width: 90px; max-width: 240px">
        {{ (text / 100).toFixed(2) }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
<!--          <a @click="handleValueCardRecord(record)">消费记录</a>-->
<!--          <a-divider type="vertical" />-->
          <a-popconfirm
            title="确定要解绑么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleUnbindUser(record)">
            <a>解绑</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <value-card-consume-record-list
      ref="valueCardModal"
      v-if="value_card_visible"
      :visible.sync="value_card_visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleValueCardCancel"
    />
    <user-bind-value-card-form
      ref="addValueCardModal"
      v-if="add_value_card_visible"
      :visible.sync="add_value_card_visible"
      :loading="addConfirmLoading"
      :model="mdl"
      @cancel="()=>this.add_value_card_visible=false"
      @ok="handleAddValueCardOk"
    />
  </a-card>
</template>
<script>

import { userBindValueCard, userBindValueCardList, userUnbindValueCard } from '@/api/shop_value_card'
import ValueCardConsumeRecordList from '@/views/a-applet/shop_manage/value_card/ValueCardConsumeRecordList'
import UserBindValueCardForm from '@/views/a-applet/shop_manage/value_card/UserBindValueCardForm'
import { STable } from '@/components'
export default {
  name: 'ValueCardList',
  components: {
    STable,
    ValueCardConsumeRecordList,
    UserBindValueCardForm
  },
  data () {
    return {
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '卡号',
          ellipsis: true,
          dataIndex: 'value_card'
        },
        {
          title: '金额',
          ellipsis: true,
          dataIndex: 'value_card_value',
          scopedSlots: { customRender: 'price_render' }
        },
        {
          title: '绑定时间',
          ellipsis: true,
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: false,
      queryParam: {},
      mdl: {},
      value_card_visible: false,
      add_value_card_visible: false,
      confirmLoading: false,
      addConfirmLoading: false,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return userBindValueCardList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.queryParam.user_id = this.$route.query.id
  },
  methods: {
    handleValueCardCancel () {
      this.value_card_visible = false
    },
    handleValueCardRecord (record) {
      this.mdl = record
      this.mdl['card_no'] = record.value_card
      this.value_card_visible = true
    },
    handleAddValueCard () {
      this.add_value_card_visible = true
    },
    handleAddValueCardOk () {
      const form = this.$refs.addValueCardModal.form
      this.addConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          values.user_id = this.queryParam.user_id
          userBindValueCard(values).then(res => {
            if (res.code === 1000) {
              this.add_value_card_visible = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            }
          }).finally(() => {
            this.addConfirmLoading = false
          })
        } else {
          this.addConfirmLoading = false
        }
      })
    },
    handleUnbindUser (record) {
      userUnbindValueCard(undefined, record.id).then(res => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        } else {
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
</script>
<style>
</style>
